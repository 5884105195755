import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home-page',
        meta: {
            title: 'Home'
        },
        component: () => import(/* webpackChunkName: "crawls-home" */ './views/homePage.vue')
    },
    {
        path: '/crawl/:slug',
        name: 'crawl-single',
        meta: {
            title: 'Crawl :slug'
        },
        component: () => import(/* webpackChunkName: "crawl-single" */ './views/crawlSingle.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
