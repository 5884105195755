<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>

/* --color-primary-bg: #2e2d2d;
--color-secondary-bg: #1a1a1a;
--color-tertiary-bg: #262626; */

body {
    background-color: #262626;
    margin: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

</style>
